import * as React from "react";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { customerReferences } from "./customerReferences";

export default function LogoCollection() {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box
      id="logoCollection"
      sx={{
        background: "white",
        padding: { xs: "1.5rem 1rem", md: "3rem 2rem" },
        zIndex: 500,
        opacity: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: { xs: 4, md: 5 },
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {customerReferences.map((customer) => (
          <Box
            key={customer.key}
            sx={{
              flex: {
                xs: "1 1 100%", // Full width on mobile
                sm: "0 1 auto", // Let logos take their natural size on larger screens
              },
              minWidth: {
                xs: "200px",
                sm: "auto",
              },
              maxWidth: {
                xs: "100%",
                sm: "auto",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <Tooltip title={customer.companyName} placement="bottom" arrow>
              <a
                href={customer.websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <img
                  src={customer.logoUrl}
                  alt={customer.companyName}
                  style={{
                    height: "auto",
                    width: "300px",
                  }}
                />
              </a>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
