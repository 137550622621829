export const customerReferences = [
  {
    websiteUrl: "https://mandarinfrukt.se",
    logoUrl: "https://storage.googleapis.com/pifa.se/mandarin.png",
    companyName: "Mandarin Frukt & Grönt AB",
    key: "Mandarin",
  },
  {
    websiteUrl: "mailto:info@opfrukt.se",
    logoUrl: "https://storage.googleapis.com/pifa.se/op.png",
    companyName: "OP Frukt & Grönt AB",
    key: "OP Frukt",
  },
  {
    websiteUrl: "https://fgcentralen.se",
    logoUrl: "https://storage.googleapis.com/pifa.se/fgc.png",
    companyName: "FG Centralen AB",
    key: "FGC",
  },
  {
    websiteUrl: "https://ethfruktogront.se",
    logoUrl: "https://storage.googleapis.com/pifa.se/eth.png",
    companyName: "ETH Frukt & Grönt Aktiebolag",
    key: "ETH",
  },
  {
    websiteUrl: "https://odlarlaget.se",
    logoUrl:
      "https://storage.googleapis.com/pifa.se/companies/6617c990824414844797191b/logos/wordmark/odlarlaget_row.png",
    companyName: "Svenska Odlarlaget ek. för.",
    key: "SOL",
  },
  {
    websiteUrl: "https://svenskasvampar.se",
    logoUrl:
      "https://storage.googleapis.com/pifa.se/companies/662b8680241c5b2e575ce5fa/logos/combinationMark/mitson.logo.jpg",
    companyName: "Mitson Bär & Svamp AB",
    key: "Mitson",
  },

  {
    websiteUrl: "https://www.ludvikaparti.se/",
    logoUrl:
      "https://storage.googleapis.com/pifa.se/companies/66f02fbb19d6fe65265106ea/logos/wordmark/ludvika.png",
    companyName: "Aktiebolaget Ludvika Partiaffär",
    key: "Ludvika",
  },
  {
    websiteUrl: "https://ggbfruit.se/",
    logoUrl:
      "https://storage.googleapis.com/pifa.se/companies/672c9ef48ede72414ba1e2f1/logos/combinationMark/combinationmark.png",
    companyName: "GGB Fruit Scandinavia AB",
    key: "GGB",
  },
];
